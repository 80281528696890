@tailwind components;
@tailwind utilities;

/* include spinners.css */
@import url('spinners.css');

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  display: block;
}
/* Reset ol, ul, li */
ol, ul, li {
  list-style: none;
}
/* reset input and textarea */
input, textarea {
  border: none;
  outline: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}
/* reset button */
button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://csscdn.herokuapp.com/0001/styles.css');

input[type="color"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 5em;
    padding: 0;
    width: 15em;
  }
  
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
  ::-webkit-color-swatch{
    border: 0;
    border-radius: 0;
  }
  
  ::-moz-color-swatch{
    border: 0;
  }

  .article-display {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .article-display h1,
  .article-display h2,
  .article-display h3,
  .article-display h4,
  .article-display h5,
  .article-display h6 {
    margin-top: 1.5em;
    margin-bottom: 0.75em;
  }
  
  .article-display p {
    margin-bottom: 1em;
  }
  
  .article-display a {
    color: #0070f3;
    text-decoration: none;
  }
  
  .article-display a:hover {
    text-decoration: underline;
  }
  
  .article-display ul,
  .article-display ol {
    margin-bottom: 1em;
  }
  
  .article-display blockquote {
    margin: 1em 0;
    padding-left: 1em;
    border-left: 3px solid #ccc;
  }

  .article-display code {
    display: inline-block;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 3px;
    padding: 0.2em 0.4em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 85%;
  }
  

  .article-display h1:first-child,
.article-display h2:first-child,
.article-display h3:first-child,
.article-display h4:first-child,
.article-display h5:first-child,
.article-display h6:first-child {
  margin-top: 0;
}

.reset {
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  color: inherit;
  line-height: inherit;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

