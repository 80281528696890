@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .spinner-1 {
    width: 40px;
    height: 40px;
    border: 4px solid var(--f-color--main);
    border-top-color: transparent;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
  }
  
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .spinner-2 {
    position: relative;
    width: 40px;
    height: 40px;
  }
  
  .spinner-2::before,
  .spinner-2::after,
  .spinner-2 {
    content: "";
    position: absolute;
    top: calc(50% - 4px);
    width: 8px;
    height: 8px;
    background-color: var(--f-color--main);
    border-radius: 50%;
    animation: bounce 0.6s ease-in-out infinite;
  }
  
  .spinner-2::before {
    left: calc(50% - 18px);
    animation-delay: -0.2s;
  }
  
  .spinner-2::after {
    left: calc(50% + 10px);
    animation-delay: -0.4s;
  }
  
  @keyframes ring-rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .spinner-3 {
    position: relative;
    width: 40px;
    height: 40px;
  }
  
  .spinner-3::before,
  .spinner-3::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid var(--f-color--main);
    border-color: var(--f-color--main) transparent var(--f-color--main) transparent;
    border-radius: 50%;
    animation: ring-rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  
  .spinner-3::after {
    border-color: transparent var(--f-color--main) transparent var(--f-color--main);
    animation-duration: 1.5s;
  }
  @keyframes figure-eight {
    0% {
      transform: translateY(0) translateX(-50%);
    }
    25% {
      transform: translateY(-50%) translateX(0);
    }
    50% {
      transform: translateY(0) translateX(50%);
    }
    75% {
      transform: translateY(50%) translateX(0);
    }
    100% {
      transform: translateY(0) translateX(-50%);
    }
  }
  
  .spinner-4 {
    position: relative;
    width: 100px;
    height: 60px;
  }
  
  .spinner-4::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    background-color: var(--f-color--main);
    border-radius: 50%;
    animation: figure-eight 4s ease-in-out infinite;
  }

  @keyframes bounceAround {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
  }
  
  .spinner-5 {
    position: relative;
    width: 40px;
    height: 40px;
  }
  
  .spinner-5::before,
  .spinner-5::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: var(--f-color--main);
    border-radius: 50%;
    animation: bounceAround 0.8s ease-in-out infinite;
  }
  
  .spinner-5::before {
    left: 50%;
    top: 0;
    animation-delay: -0.4s;
  }
  
  .spinner-5::after {
    top: 50%;
    left: 0;
    animation-delay: -0.2s;
  }
  
  .spinner-5:nth-child(2) {
      transform: rotate(120deg);
    }
    .spinner-5:nth-child(3) {
      transform: rotate(240deg);
    }
  